import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import { isBrowser } from "../utils/utilities"
import { default as PublicRoute } from "../routes/PublicRoute"

import LoadingIndicator from "../components/LoadingIndicator"

const SignIn = React.lazy(() => import("../components/ResellerSignIn"))

type Props = {|
  location: Object,
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const ResellerSignInPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const signInData = data.prismicResellerSignInPage.data
  const layoutData = (data.prismicLayout && data.prismicLayout.data) || {}

  const renderSignInPage = signInProps => {
    return (
      <>
        {isBrowser && (
          <React.Suspense fallback={<LoadingIndicator />}>
            <SignIn
              data={signInData}
              layoutData={layoutData}
              location={props.location}
              lang={lang}
              loaded={!signInProps.checkingSession}
            />
          </React.Suspense>
        )}
      </>
    )
  }

  return <PublicRoute component={renderSignInPage} {...props} lang={lang} />
}

export const query = graphql`
  query($lang: String) {
    prismicResellerSignInPage(lang: { eq: $lang }) {
      ...ResellerSignInPageFragment
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo_black {
          alt
          url
        }
      }
    }
  }
`

export default withPreview(ResellerSignInPage)
