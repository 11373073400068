import { withUnpublishedPreview } from "gatsby-source-prismic"
import NotFoundPage from "@lesmills/gatsby-theme-public-pages/src/templates/NotFoundPage"
import CreateResellerAccountPage from "../templates/CreateResellerAccountPage"
import ResellerRedeemPage from "../templates/ResellerRedeemPage"
import ResellerSignInPage from "../templates/ResellerSignInPage"
import ResellerAdditionalInfoPage from "../templates/ResellerAdditionalInfoPage"

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    create_reseller_account_page: CreateResellerAccountPage,
    reseller_redeem_page: ResellerRedeemPage,
    reseller_sign_in_page: ResellerSignInPage,
    reseller_additional_info_page: ResellerAdditionalInfoPage,
  },
})
